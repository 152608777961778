<template>
  <!-- 商家信息 -->
  <div class="shopInfo">
    <div class="bg">
      <img src="https://file.dingxiangzu.com/alipay/free-event/shop.png"/>
    </div>
    <div class="infoCard">
      <img src="https://file.dingxiangzu.com/alipay/free-event/upArrow.png" class="upArrow"/>
      <div class="line">
        <div class="vertical">
          <span class="f30 c121214 lineH">{{shopInfo.shopName}}</span>
          <span class="auth f18">已认证</span>
        </div>
      </div>
      <div class="info">
        <div>企业主体名称：{{shopInfo.companyName}}</div>
        <div>企业信用代码：{{shopInfo.creditCode}}</div>
        <div>企业营业执照：
          <span class="look f24" @click='look'>点击查看</span></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { ImagePreview } from 'vant';

const shopInfo = JSON.parse(useRoute().query.shopInfo);

const look = () => {
  console.log(shopInfo.businessLicenseUrl);
  ImagePreview([shopInfo.businessLicenseUrl]);
};
</script>
<style lang="scss" scoped>
.infoCard {
  width: 702px;
  margin: auto;
  /* height: 360px; */
  background: #ffffff;
  padding: 31px 20px 36px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: -84px;
  position: relative;
}
.upArrow{
  width: 35px;
  height: 18px;
  position: absolute;
  top: -17px;
  left: 333px;
}

.shopInfo-icon {
  width: 90px;
  height: 90px;
  display: inline-block;
  vertical-align: middle !important;
  margin-right: 15px;
}

.auth {
  width: 76px;
  height: 28px;
  background: rgba(22, 119, 255, 0.05);
  border-radius: 14px;
  color: #3594FF;
  line-height: 28px;
  text-align: center;
  margin-left: 13px;
  padding: 2px 11px;
  box-sizing: border-box;
  vertical-align: 5px;
}

.line {
  padding-bottom: 30px;
  border-bottom: 1px solid #F2F2F2;
  display: flex;
}

.info {
  margin-top: 28px;
}

.info div {
  color: #666666;
  font-size: 24px;
  line-height: 48px;
}

.look {
  color: #3594FF;
}

.vertical {
  padding-top: 15px;
  margin: auto
}

.bg {
  width: 100%;
  height: 360px;
  background: url('https://file.dingxiangzu.com/alipay/free-event/shopInfoBg.png') no-repeat;
  background-size: 100% 360px;
  text-align: center;
}

.bg img {
  width: 134px;
  height: 134px;
  margin-top: 90px;
}
</style>
